import cl from '../assets/styles/Cargo.module.scss'
import Header from '../components/Header';
import LeftSideMenu from '../components/LeftSideMenu';
import Main from '../components/Main';
const Cargo = (props) => {
    return ( 
        <>
                <Header/>
                <div className='container'>
                    <div className={cl.main}>
                        <LeftSideMenu/>
                        <Main/>
                    </div>
                </div>
        </>
     );
}
 
export default Cargo;