import $api from "../api/http/index";

export default class GetCellByBarcodeService{
    static async fetchBarcode(id_pvz,barcode){
        // return $api.get(`/pickuppoints/cell/${id_pvz}/barcode/${barcode}`,{
        //     headers:{
        //         Authorization:'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjUwYzA2MTc5LWYyMzYtNDEyMC1hOTgxLTMzZGY0ODU4MWVhYiIsInJvbGVzIjoid29ya2VyIiwiZXhwIjoxNzI3OTQ3MTk1fQ.aFjSQnuzj-t-mvnvxmxMUhXLM05lDtVDRdNpLptQrkc'
        //     }
        // })
        return $api.get(`/v1/pickuppoints/cell/${id_pvz}/barcode/${barcode}`)
    }
}