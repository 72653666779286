import cl from '../assets/styles/Orders.module.scss'
import {ReactComponent as ThreeVerticalDots} from '../assets/img/icons/dots-vertical.svg'
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddOrder, ClearOrders, RemoveOrder } from '../store/redusers/OrdersSlice';
import GetOrderForAcceptingService from '../services/GetOrderForAcceptingService';
import ReactPaginate from 'react-paginate';
import BatchStatusUpdateService from '../services/BatchStatusUpdateService';
import Dropdown from './UI/DropDownMenu';
import { ClearBarcode_id } from '../store/redusers/BarcodeSlice';

const Orders = (props) => {
    const orders = useSelector(state => state.orders.orders)
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const Refs = useRef([])
    const itemsPerPage = 11
    const offset = currentPage * itemsPerPage;
    // Определяем, какие элементы отображать на текущей странице
    const currentItems = orders.slice(offset, offset + itemsPerPage);
    const barcode_id = useSelector(state => state.barcode.barcode_id)
    const user = useSelector(state => state.me.user)
    const dispatch = useDispatch()

    async function UpdateStatuses(orders,status){
        const orders_ids = []
        for(let i = 0; i < orders.length;i++){
            orders_ids.push(orders[i].order_id)
        }
        if(!orders_ids.length){
            alert('NULL ORDERS IDS')
            return
        }else{
            try{
                const responce = await BatchStatusUpdateService.fetchUpdate(orders_ids,status)
                console.log(responce)
                alert('Статусы заказов обновлены...')
            }catch(error){
                console.log(error.response.data.error.messages)
                alert('Ошибка: ' + error.response.data.error.messages)
            }
        }
    }


    async function getOrdersForAccepting(order_track_num,pup_id,cell_id) {
        let coincidence = false

        for(let i = 0; i < orders.length; i++){
            console.log(orders[i].order_id + ' ' + order_track_num)
            if(orders[i].tracknumber === order_track_num){
                dispatch(RemoveOrder(orders[i].order_id))
                if(!orders.length){
                    dispatch(ClearOrders())
                }
                coincidence = true
            }
        }
        if(coincidence){
            alert('Товар удален.')
            return
        }
        try{
            const responce = await GetOrderForAcceptingService.fetchOrder(
                order_track_num,
                pup_id,
                cell_id
            )
        
        const result = responce.data
        const date = new Date(result?.entity?.order?.created_at)
        const formatted_date = 
        `${date.getDate() < 10?'0' + date.getDate():
        date.getDate()}.${(date.getMonth() + 1) < 10?'0' + (date.getMonth() + 1):
        date.getMonth() + 1}.${date.getFullYear()}`;

        dispatch(AddOrder({
            order_id:result?.entity?.order?.id,
            tracknumber:result?.entity?.order?.tracking_number,
            created_at:formatted_date,
            name_order:result?.entity?.order?.name,
            order_status:result?.entity?.order?.order_status,
            weight:result?.entity?.order?.weight,
            warning:result?.entity?.warning,
        }))}catch(error){
            console.log(error.response.data.error.messages)
            alert('Ошибка: ' + error.response.data.error.messages)
        }
    }

    // Обработчик изменения страницы
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };
    
    function checkIndex(num){
        const result = (num / 2) == Math.floor(num / 2)
        return result
    }
    const setRef = (el, index) => {
        Refs.current[index] = el;
    };
    const toggleDropdown = (index) => {
        setDropdownOpen((prev) => (prev === index ? null : index));
    };
    
    const closeDropdown = () => {
        setDropdownOpen(null);
    };
    function WarnHover(e){
        if(e.target.id === '' || e.target.id === null)return
        Refs.current[e.target.id].previousElementSibling.style.display = 'block'
    }
    function WarnHoverLeave(e){
        if(e.target.id === '' || e.target.id === null)return
        Refs.current[e.target.id].previousElementSibling.style.display = 'none'
    }
    function cancelOrdersScanAndBarcodeScan(){
        dispatch(ClearBarcode_id())
        dispatch(ClearOrders())
        alert('Отменено')
    }
    useEffect(()=>{
        const handleKeyDown = (event) => {
            if ((event.ctrlKey && event.key === 'v') || (event.ctrlKey && event.key === 'м')) {
                setIsCtrlPressed(true);
                console.log(Math.random() * 1)
            }
        };

        const handleKeyUp = (event) => {
            if (event.key === 'Control') {
              setIsCtrlPressed(false);
            }
          };

        const handlePaste = (event) => {
            if(barcode_id === null || user.pickup_point_info.id === null)return
            if (isCtrlPressed) {
                const pastedText = event.clipboardData.getData('text');
                if(pastedText === null || pastedText === '')return
                console.log('Pasted text:', pastedText);
                // getOrdersForAccepting('9a438a9b-4e83-43de-b2d7-5429b8338685','79ada442-4739-47d4-996f-256293813bc4',barcode_id.id)
                getOrdersForAccepting(pastedText,user.pickup_point_info.id,barcode_id.id)
                setIsCtrlPressed(false); // Сброс состояния после обработки
            }
        };

        if(props.scan){
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);
            window.addEventListener('paste', handlePaste);
        }else{
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('paste', handlePaste);
        }

        return () => {
            //Размантирование/unmount для избежания утечки памяти
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('paste', handlePaste);
        };
    },[isCtrlPressed,props.scan])

    return ( 
        <>
            <div className={cl.wrapper}>
                <div className={cl.container}>
                    <h6 className={cl.orders_title}>Заказы</h6>
                
                    <div className={cl.table}>
                        <div className={cl.table__head}>
                            {/* <div className={cl.table__head_checkbox} onClick={()=>clickAllCheckbox()}>All</div> */}
                            <div className={cl.table__head_checkbox}></div>
                            <div className={cl.table__head_td + ' ' + cl.date}><h3>Дата заказа</h3></div>
                            <div className={cl.table__head_td + ' ' + cl.name}><h3>Наименование</h3></div>
                            <div className={cl.table__head_td + ' ' + cl.tracknum}><h3>Трек номер</h3></div>
                            <div className={cl.table__head_td + ' ' + cl.status}><h3>Статус</h3></div>
                            <div className={cl.table__head_td + ' ' + cl.weight}><h3>Вес</h3></div>
                            <div className={cl.table__head_menu}></div>
                        </div>
                        <div className={cl.table__body}>

                            {currentItems.map((el,index)=>
                                <div 
                                key={el.order_id}
                                className=
                                {`${checkIndex(index)?cl.tr__item + ' ' +  cl.even_number:cl.tr__item} ${el.warning != '' && el.warning?cl.warning:''}`}>
                                    {el.warning != '' && el.warning?

                                    <div className={cl.warn_block}>

                                        <span 
                                        className=
                                        {cl.warn_description}
                                        >
                                            {el.warning}
                                        </span>

                                        <img src={require('../assets/img/icons/warning.png')} 
                                        alt="warn" 
                                        className={cl.warn_svg}
                                        onMouseEnter={(e)=>WarnHover(e)}
                                        ref={(element) => setRef(element, el.order_id)}
                                        onMouseLeave={(e)=>WarnHoverLeave(e)}
                                        id={el.order_id}
                                        />
                                    </div>:null}

                                    <div className={cl.table__body_checkbox}>

                                    </div>
                                    <div className={cl.date}>
                                        <p>
                                            {el.created_at}
                                        </p>
                                    </div>
                                    <div className={cl.name}>
                                        <p>{el.name_order}</p>
                                    </div>
                                    <div className={cl.tracknum}>
                                        <p>
                                            {el.tracknumber}
                                        </p>
                                    </div>
                                    <div className={cl.status}>
                                        <p>
                                            {el.order_status}
                                        </p>
                                    </div>
                                    <div className={cl.weight}>
                                        <p>
                                            {el.weight}
                                        </p>
                                    </div>
                                    <div className={cl.table__body_menu}>
                                        <Dropdown
                                            isOpen={dropdownOpen === el.order_id}
                                            onClose={closeDropdown}
                                            order_id={el.order_id}
                                        />
                                        <ThreeVerticalDots
                                            className={cl.verticalDots}
                                            onClick={() => toggleDropdown(el.order_id)}
                                        />
                                    </div>
                                </div>
                            )}

                            
                        </div>
                    </div>


                    <div className={cl.pagination_block}>
                        <ReactPaginate
                        previousLabel={'Back'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(orders.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={handlePageClick}
                        containerClassName={cl.pagination}
                        pageClassName={cl.page_btn}
                        activeClassName={cl.active_page}
                        previousClassName={cl.previous_btn}
                        nextClassName={cl.next_btn}
                        />
                    </div>
                
                </div>
            </div>
            <div className={cl.accept__order_btn}>
                <button 
                    className={cl.btn_new} 
                    onClick={()=>UpdateStatuses(orders,2)}
                    >Принять
                    </button>
                {!orders.length && barcode_id === null?
                null:
                <button className={cl.btn_new + ' ' + cl.btn_cancel} onClick={()=>cancelOrdersScanAndBarcodeScan()}>Отменить</button>
                }
            </div>
            
            
        </>
     );
}
 
export default Orders;