import { useSelector } from 'react-redux';
import cl from '../assets/styles/Main.module.scss'
import Acceptance from './Acceptance';
import Issuance from './Issuance';
import Warehouse from './Warehouse';

const Main = (props) => {
    const activeMenu = useSelector(state => state.menu.activeMenu)
    return ( 
            <div className={cl.wrapper}>
                {activeMenu === 'acceptance'?<Acceptance/>:null}
                {activeMenu === 'issuance'?<Issuance/>:null}
                {activeMenu === 'warehouse'?<Warehouse/>:null}
            </div>
     );
}
 
export default Main;