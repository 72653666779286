import { createSlice } from "@reduxjs/toolkit";

function deleteFromLocalStorageEmptyItem(){
    localStorage.removeItem('barcode_id')
    return null
}

const BarcodeSlice = createSlice({
    name:'BarcodeSlice',
    initialState:{
        barcode_id:
        JSON.parse(localStorage.getItem('barcode_id')) === null? 
        deleteFromLocalStorageEmptyItem():
        JSON.parse(localStorage.getItem('barcode_id')).barcode_id
    },
    reducers:{
        ChangeBarcode_id(state,action){
            state.barcode_id = action.payload
            localStorage.setItem('barcode_id', JSON.stringify(state));
        },
        ClearBarcode_id(state,action){
            localStorage.removeItem('barcode_id')
            state.barcode_id = null
        }
    }
})

export default BarcodeSlice.reducer
export const {ChangeBarcode_id,ClearBarcode_id} = BarcodeSlice.actions