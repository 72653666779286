import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
    name:'LoginSlice',
    initialState:{
        isAuth:false
    },
    reducers:{
        changeAuth(state,action){
            state.isAuth = action.payload
        }
    }
})

export default AuthSlice.reducer
export const {changeAuth} = AuthSlice.actions