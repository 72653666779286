import { createSlice } from "@reduxjs/toolkit";

const MenuSlice = createSlice({
    name:'MenuSlice',
    initialState:{
        activeMenu:'acceptance'
    },
    reducers:{
        ChangeActive(state,action){
            state.activeMenu = action.payload
        }
    }
})

export default MenuSlice.reducer
export const {ChangeActive} = MenuSlice.actions