import { createSlice } from "@reduxjs/toolkit";

function deleteFromLocalStorageEmptyItem(){
    localStorage.removeItem('orders')
    return []
}

const ordersSlice = createSlice({
    name:'ordersSlice',
    initialState:{
        orders:
        JSON.parse(localStorage.getItem('orders')) === null? 
        deleteFromLocalStorageEmptyItem():
        JSON.parse(localStorage.getItem('orders')).orders
    },
    reducers:{
        AddOrder(state,action){
            state.orders.push(action.payload)
            localStorage.setItem('orders', JSON.stringify(state));
        },
        RemoveOrder(state,action){
            const orderIdToRemove = action.payload
            state.orders = state.orders.filter(order => order.order_id != orderIdToRemove)
            localStorage.setItem('orders', JSON.stringify(state));
        },
        ClearOrders(state,action){
            localStorage.removeItem('orders')
            state.orders = []
        }
    }
})

export default ordersSlice.reducer
export const {AddOrder,RemoveOrder,ClearOrders} = ordersSlice.actions