import $api from "../api/http/index";

export default class GetOrderForAcceptingService{
    static async fetchOrder(order_track_num,pup_id,cell_id){
        return $api.post('/v1/orders/to_accept',
            {
                order_track_num:order_track_num,
                pup_id:pup_id,
                cell_id:cell_id
            }
        )
    }
}