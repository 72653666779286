import cl from '../assets/styles/Issuance.module.scss'

const Issuance = () => {
    return ( 
    <>
        <h2 className={cl.title}>Выдача товара</h2>
    </> 
    );
}
 
export default Issuance;