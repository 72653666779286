import { Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes/routes";
import Login from "./pages/Login.jsx";
import Cargo from "./pages/Cargo.jsx";

const AppRouter = (props) => {
    console.log(props.isLoading)
    if(props.isLoading){
        return (
          <>
            <h1 style={{
              display:'flex',
              width:'100%',
              height:'100%',
              fontSize:'36px',
              fontWeight:'600',
              alignItems:'center',
              justifyContent:'center'}}>
              Loading...
              </h1>      
          </>
        )
    }
    if(props.isAuth){
        return(
            <Routes>
            {privateRoutes.map(route =>
                    <Route
                        Component={route.component}
                        path={route.path}
                        exact={route.exact}
                        key={route.path}
                    />
                )}
                
                <Route path="/*" element={<Cargo to="/cargo"/>}/>
        </Routes>
        )
    }else{
        return(
            <Routes>
                {publicRoutes.map(route =>
                    <Route
                        Component={route.component}
                        path={route.path}
                        exact={route.exact}
                        key={route.path}
                    />
                )}
                
                <Route path="/*" element={<Login to="/login"/>}/>
            </Routes>
        )
    }
}
 
export default AppRouter;