import { createSlice } from "@reduxjs/toolkit";

const UserInfoSlice = createSlice({
    name:'UserInfoSlice',
    initialState:{
        user:null
    },
    reducers:{
        ChangeUser(state,action){
            state.user = action.payload
        }  
    }
})

export default UserInfoSlice.reducer
export const {ChangeUser} = UserInfoSlice.actions