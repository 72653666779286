import cl from '../assets/styles/Header.module.scss'
import {ReactComponent as UserSVG} from '../assets/img/icons/user-square-thin.svg'
import {ReactComponent as LogoSVG} from '../assets/img/logo_cargo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { changeAuth } from '../store/redusers/AuthSlice';

const Header = (props) => {
    const user = useSelector(state => state.me.user)
    const dispatch = useDispatch()
    function logaut(){
        localStorage.removeItem('token')
        dispatch(changeAuth(false))
    }
    return (
        <div className={cl.underline}>
            <div className='container'>
                <div className={cl.wrapper}>
                    {/* <LogoSVG className={cl.logo} width="50" height="50"/> */}
                    <img className={cl.logo} src={require('../assets/img/logo_cargo.PNG')}/>

                    <div className={cl.user_info}>
                        <div className={cl.location}>
                            <h6>{user?.pickup_point_info.name}</h6>
                            <p>{user?.pickup_point_info.address}</p>
                        </div>

                        <div className={cl.user}>
                            <div>
                                <h6>{user?.display_name}</h6>
                                <p>{user?.username}</p>
                            </div>

                            <div>
                                <UserSVG className={cl.userSVG} onClick={()=>logaut()}/>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
     );
}
 
export default Header;