import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
import './assets/styles/reset.css'
import './assets/styles/main.css'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeAuth } from "./store/redusers/AuthSlice";
import GetMeService from "./services/GetMeService";
import { ChangeUser } from "./store/redusers/UserInfoSlice";

const App = () => {
  const [isLoading,setIsLoading] = useState(true)
  const isAuth = useSelector(state=> state.auth.isAuth)
  const dispatch = useDispatch()

  async function getMe(){
    try{
      const responce = await GetMeService.fetchMe()

      return responce
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    const token =  localStorage.getItem('token')

    // const handleBeforeUnload = (event) => {
    //   event.preventDefault();
    //   event.returnValue = ''; // Для некоторых браузеров нужно установить это значение
    // };

    // window.addEventListener('beforeunload', handleBeforeUnload);

    if(token === null || token === undefined || token === ''){
      dispatch(changeAuth(false))
      setIsLoading(false)
    }else{
      getMe().then((data)=>{
        if(data != null || data != undefined || data != ''){
          console.log(data.data)
          dispatch(ChangeUser(data.data.entity))
          dispatch(changeAuth(true))
        }
      }).finally(()=>setIsLoading(false))
    }
    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  },[isAuth])
    return ( 
      <BrowserRouter>
        <AppRouter isAuth={isAuth} isLoading={isLoading}/> 
      </BrowserRouter>
     );
}
 
export default App;
