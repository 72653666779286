import $api from "../api/http/index";

export default class BatchStatusUpdateService{
    static async fetchUpdate(order_ids,status){
        return $api.put('/v1/orders/update_statuses',
            {
                order_ids:order_ids,
                status:status,
            }
        )
    }
}