import { useDispatch, useSelector } from "react-redux";
import { ChangeBarcode_id } from "../store/redusers/BarcodeSlice";
import { useEffect, useMemo, useState } from "react";
import GetCellByBarcodeService from "../services/GetCellByBarcodeService";

const BarcodeScan = () => {
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);
    const [barcodeIsScanned,setBarcodeIsScanned] = useState(false)
    const barcode_id = useSelector(state => state.barcode.barcode_id)
    const user = useSelector(state => state.me.user)
    const dispatch = useDispatch()

    async function getBarcodeId(pvz_id,barcode) {
        try{
            const responce = await GetCellByBarcodeService.fetchBarcode(pvz_id,barcode)
            dispatch(ChangeBarcode_id({id:responce?.data?.entity?.id,number:responce?.data?.entity.number}))
        }catch(error){
            console.log(error.response.data.error.messages)
            alert('Ошибка: ' + error.response.data.error.messages)
        }

    }
    useMemo(()=>{
        if(barcode_id != null){
            setBarcodeIsScanned(true)
        }else{
            setBarcodeIsScanned(false)
        }
    },[barcode_id])

    useEffect(()=>{
        const handleKeyDown = (event) => {
            if ((event.ctrlKey && event.key === 'v') || (event.ctrlKey && event.key === 'м')) {
                setIsCtrlPressed(true);
                console.log(Math.random() * 1)
            }
        };

        const handleKeyUp = (event) => {
            if (event.key === 'Control') {
              setIsCtrlPressed(false);
            }
          };

        const handlePaste = (event) => {
            if(user.pickup_point_info.id === null)return
            if (isCtrlPressed) {
                const pastedText = event.clipboardData.getData('text');
                getBarcodeId(user.pickup_point_info.id,pastedText) // Исправить харкод
                console.log('Barcode:', pastedText);
                setIsCtrlPressed(false); // Сброс состояния после обработки
            }
        };

        if(!barcodeIsScanned){
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);
            window.addEventListener('paste', handlePaste);
        }else{
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('paste', handlePaste);
        }

        return () => {
            //Размантирование/unmount для избежания утечки памяти
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('paste', handlePaste);
        };
    },[isCtrlPressed,barcodeIsScanned])
        return ( 
            <>
                <h3 style={{fontSize:'24px',margin:'0 0 0 20px'}}>Просканируйте ячейку</h3>
            </>
        );
}
 
export default BarcodeScan;

// scan() id: 35cdd986-a051-4589-b0fd-93df7725c092

// switch {
//     case 1: barcode_id == null => ячейка не просканирована и ее надо открыть
//     case 2: barcode_id != null && scanning_id == barcode_id => ячейка закрываем, если нет значит это ордер если нет, то мы проверяем в массиве где лежат ордера и если уже такой id есть значит мы его удаляем