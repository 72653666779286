import { useEffect, useRef} from "react";
import {ReactComponent as GarbageSVG} from '../../assets/img/icons/garbage.svg'
import cl from '../../assets/styles/DropDownMenu.module.scss'
import { ClearOrders, RemoveOrder } from "../../store/redusers/OrdersSlice";
import { useDispatch, useSelector } from "react-redux";
const Dropdown = ({isOpen,onClose,order_id}) => {
    // const [isOpen, setIsOpen] = useState(false);
    const orders = useSelector(state => state.orders.orders)
    const dropdownRef = useRef(null);
    const dispatch = useDispatch()
    // const toggleDropdown = () => {
    //   setIsOpen((prev) => !prev);
    // };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    function removeOrder(){
      dispatch(RemoveOrder(order_id))

      if(!orders.length){
        dispatch(ClearOrders())
      }

    }

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClose]);

    if (!isOpen) return null;

    return (
    //   <div ref={dropdownRef}>
    //     <button onClick={toggleDropdown}>⋮</button>
    //     {isOpen && (
    //       <div className="dropdown-menu">
    //         <ul>
    //           <li>Item 1</li>
    //           <li>Item 2</li>
    //           <li>Item 3</li>
    //         </ul>
    //       </div>
    //     )}
    //   </div>
    // <div className={cl.drop_down_menu} ref={dropdownRef}>
    //     <ul className={cl.submenu}>
    //         <li className={cl.delete_order} id={el.order_id}>
    //             Удалить
    //             <GarbageSVG className={cl.garbage_svg}/>
    //         </li>
    //     </ul>
    // </div>
    <div className={cl.drop_down_menu} ref={dropdownRef}>
        <ul className={cl.submenu}>
            <li className={cl.delete_order} onClick={()=>removeOrder()}>
                Удалить
                <GarbageSVG className={cl.garbage_svg}/>
            </li>
        </ul>
    </div>
    );
  };
  
  export default Dropdown;