import { createSlice } from "@reduxjs/toolkit";

const PaginationSlice = createSlice({
    name:'PaginationSlice',
    initialState:{
        page_limit:10,
        pages:1,
        current_page:1
    },
    reducers:{
        NextPage(state,action){
            state.current_page = state.current_page + 1
        },
        BackPage(state,action){
            state.current_page = state.current_page - 1
        },
        NewCurrentPage(state,action){
            state.current_page = action.payload
        },
        ChangePages(state,action){
            state.pages = action.payload
        }
    }
})

export default PaginationSlice.reducer
export const {NextPage,BackPage,NewCurrentPage,ChangePages} = PaginationSlice.actions