import { combineReducers, configureStore } from "@reduxjs/toolkit";
import OrdersSlice from "./redusers/OrdersSlice";
import MenuSlice from "./redusers/MenuSlice";
import AuthSlice from "./redusers/AuthSlice";
import BarcodeSlice from "./redusers/BarcodeSlice";
import PaginationSlice from "./redusers/PaginationSlice";
import UserInfoSlice from "./redusers/UserInfoSlice";

const rootReducer = combineReducers({
    orders: OrdersSlice,
    menu:MenuSlice,
    auth:AuthSlice,
    barcode:BarcodeSlice,
    pagination:PaginationSlice,
    me:UserInfoSlice
})

// export const store = () =>{
//     return configureStore({
//         reducer:rootReducer
//     })
// }
export const store = configureStore({
    reducer: rootReducer,
});