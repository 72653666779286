import $api from "../api/http/index";
import { AxiosResponse } from "axios";

export default class AuthService{
    static async login(login,password){
        return $api.post('/v1/employees/login', {

            username:login,
            password:password
        },{
            headers:{
                'Content-Type': 'application/json'
            }
        })
    }
    // static async registration(login,password){
    //     return $api.post('/api/registration', {login, password})
    // }
    // static async logout(){
    //     return $api.post('/api/logout')
    // }
}