import cl from '../assets/styles/LeftSideMenu.module.scss'
import {ReactComponent as IconPackage} from '../assets/img/icons/package-check.svg'
import {ReactComponent as IconPackagePlus} from '../assets/img/icons/package-plus.svg'
import {ReactComponent as IconWareHouse} from '../assets/img/icons/home-line.svg'
import {ReactComponent as IconDashboard} from '../assets/img/icons/align-bottom-02.svg'
import { useDispatch, useSelector } from 'react-redux'
import { ChangeActive } from '../store/redusers/MenuSlice'


const LeftSideMenu = (props) => {
    const activeMenu = useSelector(state=> state.menu.activeMenu)
    const dispatch = useDispatch()
    return (
            <div className={cl.wrapper}>
                <ul className={cl.left_menu}>

                    <li className={`${cl.line_none}` + `${activeMenu === 'acceptance'?' ' + cl.on_menu_hover:''}`} 
                        onClick={()=>dispatch(ChangeActive('acceptance'))}
                    >
                        <IconPackagePlus className={cl.iconPackagePlus}/>
                        <span>Приемка товара</span>
                    </li>
                    <li onClick={()=>dispatch(ChangeActive('issuance'))}
                        className={`${activeMenu === 'issuance'?' ' + cl.on_menu_hover:''}`}
                    >
                        <IconPackage className={cl.iconPackage}/>
                        <span>Выдача товара</span>
                    </li>
                    <li onClick={()=>dispatch(ChangeActive('warehouse'))}
                        className={`${activeMenu === 'warehouse'?' ' + cl.on_menu_hover:''}`}
                    >
                        <IconWareHouse className={cl.iconWareHouse}/>
                        <span>Склад</span>
                    </li>
                    <li onClick={()=>dispatch(ChangeActive('acceptance'))}
                        
                    >
                        <IconDashboard className={cl.iconDashboard}/>
                        <span>Управление</span>
                    </li>

                </ul>
            </div>
     );
}
 
export default LeftSideMenu;