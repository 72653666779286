// import ButtonScan from './UI/ButtonScan';
import cl from '../assets/styles/Acceptance.module.scss'
import { useEffect, useState } from 'react';
import BarcodeScan from './BarcodeScan';
import Orders from './Orders';
import { useSelector } from 'react-redux';
import {ReactComponent as OkSVG} from '../assets/img/icons/ok.svg'

const Acceptance = () => {
    const [scan,setScan] = useState(false)
    const barcode_id = useSelector(state => state.barcode.barcode_id)

    useEffect(()=>{
        if(barcode_id === null){
            setScan(false)
            console.log('barcode_id is null')
        }else{
            setScan(true)
        }
    },[barcode_id])
    return ( 
        <>
            <h2 className={cl.title}>Приемка товара</h2>
            
            {!scan?<BarcodeScan/>:<h3 className={cl.scan_title}><OkSVG className={cl.okSVG}/> Ячейка № {barcode_id?.number}</h3>}

            <Orders scan={scan}/>
        </>
     );
}
 
export default Acceptance;