import cl from '../assets/styles/Warehouse.module.scss'

const Warehouse = () => {
    return ( 
        <>
        <h2 className={cl.title}>Склад</h2>
        </>
     );
}
 
export default Warehouse;